import classNames from "classnames";
import dayjs from "dayjs";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import ClarificationIcon from "~/components/core/ClarificationIcon";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import DashboardGuideContext from "~/components/dashboard/DashboardGuide/DashboardGuideContext";
import { useAuthenticatedFetch } from "~/utils/http";
import { localeFormat } from "~/utils/numbers";
import { trackEvent } from "~/utils/segment";

import { DonutChart } from "./DonutChart";

const DashboardHeader: React.FC = () => {
  const { headerRef } = React.useContext(DashboardGuideContext);
  const {
    selectedUmbrella: { umbrellaAccName },
    schoolYearStartDate
  } = useRequiredAuthContext();

  const { data, loading, error } = useAuthenticatedFetch(
    "/api/v1/new_stats/quick_facts/",
    {
      umbrellaAccountId: umbrellaAccName,
      fromDt: schoolYearStartDate
    },
    true
  );

  React.useEffect(() => trackEvent("load_quick_facts", undefined), []);

  return (
    <div
      ref={headerRef}
      className="bg-white py-[16px] mx-[32px] mb-[24px] rounded-[8px] border-2 border-cream-500 flex flex-col"
    >
      <div className="w-full flex justify-center items-center gap-[8px] h2 px-[10px]">
        Total usage for School Year
        {` ${dayjs(schoolYearStartDate).format("YYYY")}-${dayjs(
          new Date(dayjs(schoolYearStartDate).year() + 1, 1, 1)
        ).format("YY")}`}
        <ClarificationIcon clarification={usageTooltip} placement="right" />
      </div>

      <div className="my-[16px] border border-cream-300" />

      <WidgetContentNew
        hasData={!!data}
        loading={loading}
        error={error}
        className="h-[133px]"
      >
        <div className="flex flex-col md:flex-row justify-center items-center px-[64px]">
          <div className="h-auto max-h-[127px] sm:h-full flex flex-col sm:flex-row md:flex-col items-stretch justify-center sm:justify-between w-full sm:w-[345px]">
            <DataDisplay
              data={data?.studentsEnrolled ?? 0}
              title="Enrolled students"
              tooltip={enrolledTooltip}
            />
            <DataDisplay
              data={data?.studentsActive ?? 0}
              title="Active students"
              tooltip={activeTooltip}
            />
          </div>

          <DonutChart
            data={[data?.sessionHours ?? 0, data?.writingLabHours ?? 0]}
          />

          <div className="flex flex-col md:flex-row  gap-[24px] h-full items-center  w-[345px] ml-[24px]">
            <div className="flex flex-col sm:flex-row md:flex-col mb-[16px] md:mb-0  gap-[16px]">
              <div className="flex items-center gap-[16px]">
                <div className="w-[20px] h-[20px] rounded-full bg-chalkboard-600" />
                <DataDisplay
                  data={data?.sessionHours ?? 0}
                  title="Live session hours"
                  noCenterAlign
                />
              </div>
              <div className="flex items-center gap-[16px]">
                <div className="w-[20px] h-[20px] rounded-full bg-blueSkies-400" />
                <DataDisplay
                  data={data?.writingLabHours ?? 0}
                  title="Writing Lab hours"
                  noCenterAlign
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row md:flex-col gap-[16px]">
              <DataDisplay
                data={data?.sessionCount ?? 0}
                title="Live sessions"
              />
              <DataDisplay
                data={data?.writingLabCount ?? 0}
                title="Writing Lab reviews"
              />
            </div>
          </div>
        </div>
      </WidgetContentNew>
    </div>
  );
};

export default DashboardHeader;

const DataDisplay: React.FC<{
  data: number;
  title: string;
  tooltip?: JSX.Element;
  noCenterAlign?: boolean;
}> = ({ data, title, tooltip, noCenterAlign }) => {
  return (
    <div
      className={classNames(
        "flex flex-col whitespace-nowrap",
        noCenterAlign ? "items-start" : "items-center sm:items-start"
      )}
    >
      <div
        className={classNames("h2", tooltip && "!text-[32px] !leading-[32px]")}
      >
        {localeFormat(data) || "—"}
      </div>
      <div className="flex items-center gap-[4px] paragraph">
        {title}
        {tooltip && (
          <ClarificationIcon clarification={tooltip} placement="right" />
        )}
      </div>
    </div>
  );
};

const usageTooltip = (
  <div>
    The account’s school year dates are shown in your{" "}
    <span className="font-semibold">Settings</span> page within{" "}
    <span className="font-semibold">Account details</span>
  </div>
);
const enrolledTooltip = (
  <div>
    <span className="font-semibold">Enrolled students</span> have logged into
    Pear Deck Tutor at least once
  </div>
);
const activeTooltip = (
  <div>
    <span className="font-semibold">Active students</span> have completed at
    least one live tutoring session or Writing Lab
  </div>
);
